/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import {} from '@material-ui/core';
import { DownChevron, IconPlusThin } from 'src/components/Icons';
import { Body, Button, Heading, SwipeableDrawer } from 'src/components/UI';
import { FavoriteSelectionCarousel } from 'src/screens/RewardsEnrollment/FavoriteSelectionScreen';
import {
    FavoriteOffersList,
    getFavoriteOffers,
} from 'src/services/easyRewardsServices';
import { mergeClasses } from 'src/stylesheets/utils';
import { InfoDrawer } from 'src/components/EasyRewards';
import { eventMetricsMap, useEventsReporter, useUserInfo } from 'src/hooks';
import moment from 'moment';
import { motion } from 'framer-motion';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { Player } from '@lottiefiles/react-lottie-player';
import styles from './styles.module.scss';

interface FavoriteCardProps {
    title: string;
    subtitle: string | null;
    img: string | null;
    onClick: () => void;
}
function FavoriteCard({ title, subtitle, img, onClick }: FavoriteCardProps) {
    return (
        <div className={styles.cardContainer} onClick={onClick}>
            <div className={styles.infoIcon}>
                {/* TODO move this to icons */}
                <img
                    id="onboarding-swipeable-elem-image"
                    src={require('../../../assets/screenshots/infoIcon.svg')}
                    alt="onboarding introduction"
                />
            </div>
            <img className={styles.cardImage} src={img || ''} alt="category" />
            <div className={styles.cardDetailsContainer}>
                <Heading
                    textAlign="textCenter"
                    type="h6"
                    className={styles.title}
                >
                    {title}
                </Heading>
                {subtitle && (
                    <p className={styles.cardSubheading}>{subtitle}</p>
                )}
            </div>
        </div>
    );
}

interface FavoriteCardAddProps {
    onClick: () => void;
}
function FavoriteCardAdd({ onClick }: FavoriteCardAddProps) {
    return (
        <div role="button" onClick={onClick} className={styles.cardContainer}>
            <div className={styles.cardPlusIconContainer}>
                <IconPlusThin />
            </div>
            <div>
                <Heading textAlign="textCenter" type="h6">
                    Add
                </Heading>
                <p className={styles.cardSubheading}>to earn points</p>
            </div>
        </div>
    );
}

interface SelectedOffersProps {
    subscribedList: FavoriteOffersList | undefined;
    isSubscribedOffersListEmpty: boolean;
    setInfoDrawerOpenOffer: (id: string) => void;
    setIsFavoriteSelectionDrawerOpen: (arg: boolean) => void;
}
function SelectedOffers({
    subscribedList,
    isSubscribedOffersListEmpty,
    setInfoDrawerOpenOffer,
    setIsFavoriteSelectionDrawerOpen,
}: SelectedOffersProps) {
    const { analyticsCustomEvent } = useEventsReporter();

    const { userInfo } = useUserInfo();

    return isSubscribedOffersListEmpty ? null : (
        <>
            {new Array(3).fill(null).map((_v, index) => {
                const offer = subscribedList?.[index];
                return offer ? (
                    <FavoriteCard
                        key={offer.id}
                        title={offer.name}
                        subtitle={offer.alternativedescription}
                        img={offer.digitalviewimage}
                        onClick={() => {
                            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                                click_text: offer.name,
                                element_location:
                                    'Your bonus points categories',
                                event_rewards_number:
                                    userInfo?.rewardsNumber?.padStart(
                                        10,
                                        '0'
                                    ) || '',
                            });
                            setInfoDrawerOpenOffer(offer.id);
                        }}
                    />
                ) : (
                    <FavoriteCardAdd
                        key={index}
                        onClick={() => {
                            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                                click_text: 'Add',
                                element_location:
                                    'Your bonus points categories',
                                event_rewards_number:
                                    userInfo?.rewardsNumber?.padStart(
                                        10,
                                        '0'
                                    ) || '',
                            });
                            setIsFavoriteSelectionDrawerOpen(true);
                        }}
                    />
                );
            })}
        </>
    );
}

function ManageFavorites() {
    const { analyticsCustomEvent } = useEventsReporter();
    const { isNotifyChooseCategory, openChooseCategory } =
        useUserUpdateContext();
    const { userInfo } = useUserInfo();
    const [isFavoriteSelectionDrawerOpen, setIsFavoriteSelectionDrawerOpen] =
        useState<boolean>(false);
    const [expandCategory, setExpandCategory] = useState<boolean>(false);

    // Info drawer state management
    const [infoDrawerState, setInfoDrawerState] = useState<{
        open: boolean;
        selectedOffer: string | null;
    }>({
        open: false,
        selectedOffer: null,
    });
    const toggleInfoDrawerOpen = (isOpen: boolean) =>
        setInfoDrawerState((oldState) => ({ ...oldState, open: isOpen }));
    const setInfoDrawerOpenOffer = (id: string) =>
        setInfoDrawerState({ open: true, selectedOffer: id });

    const { data: offerList, isFetching } = getFavoriteOffers({
        initialData: [] as unknown as FavoriteOffersList,
    });

    const subscribedList = useMemo(
        () => offerList?.filter((offer) => offer.subscribed),
        [offerList]
    );
    const isSubscribedOffersListEmpty = subscribedList?.length === 0;
    useEffect(() => {
        if (isNotifyChooseCategory) {
            setIsFavoriteSelectionDrawerOpen(true);
        }
    }, [isNotifyChooseCategory]);

    const toggleCategory = () => {
        setExpandCategory(!expandCategory);
    };

    return (
        <>
            <div className={styles.p4}>
                <div
                    className={mergeClasses([
                        styles.flex,
                        styles.flexCol,
                        styles.rowGap2,
                    ])}
                >
                    {!isSubscribedOffersListEmpty && !expandCategory ? (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            onClick={toggleCategory}
                        >
                            <div className={styles.collapseMode}>
                                <div>
                                    <Heading
                                        color="textDarkgray"
                                        type="h6"
                                        weight="bold"
                                        size={14}
                                        tabIndex={1}
                                        ariaLabel="Your bonus points categories button"
                                    >
                                        Your bonus points categories
                                    </Heading>
                                    {subscribedList &&
                                        subscribedList.length > 0 && (
                                            <Body
                                                size={12}
                                                weight="light"
                                                color="#585858"
                                                tabIndex={2}
                                                ariaLabel={`Renews on ${moment(
                                                    subscribedList[0]
                                                        ?.subscriptionenddate
                                                ).format('MM/DD/YY')}`}
                                                ariaDescription="Category information and points value may change."
                                            >
                                                {`Renews on ${moment(
                                                    subscribedList[0]
                                                        ?.subscriptionenddate
                                                ).format('MM/DD/YY')}`}
                                            </Body>
                                        )}
                                </div>
                                <div>
                                    {new Array(3)
                                        .fill(null)
                                        .map((_v, index) => {
                                            const offer =
                                                subscribedList?.[index];
                                            const tindx = 3 + index;
                                            return offer?.digitalviewimage ? (
                                                <img
                                                    key={index}
                                                    tabIndex={tindx}
                                                    aria-label="Category"
                                                    className={
                                                        styles.collapseImg
                                                    }
                                                    src={offer.digitalviewimage}
                                                    alt="categoryImg"
                                                />
                                            ) : null;
                                        })}
                                </div>
                                <div
                                    tabIndex={6}
                                    role="Button"
                                    aria-label="Expand your Bonus point category"
                                    className={styles.downIcon}
                                    style={{ transform: 'translateY(10px)' }}
                                >
                                    <DownChevron />
                                </div>
                            </div>
                        </motion.div>
                    ) : (
                        <div>
                            <div className={styles.btnContainer}>
                                <div>
                                    <Heading
                                        color="textDarkgray"
                                        type="h6"
                                        weight="bold"
                                        size={20}
                                    >
                                        Your bonus points categories
                                    </Heading>
                                    {subscribedList &&
                                        subscribedList.length > 0 && (
                                            <Body
                                                size={12}
                                                weight="bold"
                                                color="#585858"
                                            >
                                                {`Renews on ${moment(
                                                    subscribedList[0]
                                                        ?.subscriptionenddate
                                                ).format('MM/DD/YY')}`}
                                            </Body>
                                        )}
                                </div>
                                {!isSubscribedOffersListEmpty && (
                                    <>
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                analyticsCustomEvent(
                                                    eventMetricsMap.easy_rewards,
                                                    {
                                                        click_text: 'Update',
                                                        element_location:
                                                            'Your bonus points categories',
                                                        event_rewards_number:
                                                            userInfo?.rewardsNumber ||
                                                            '',
                                                    }
                                                );
                                                setIsFavoriteSelectionDrawerOpen(
                                                    true
                                                );
                                            }}
                                            color="textBlack"
                                            decoration="textUnderline"
                                            fontWeight="light"
                                            fontSize={14}
                                            className={styles.updateBtn}
                                        >
                                            Update
                                        </Button>
                                        <span
                                            className={styles.downIcon}
                                            onClick={toggleCategory}
                                            style={{
                                                transform: expandCategory
                                                    ? 'rotate(180deg) translateY(20px)'
                                                    : '',
                                            }}
                                        >
                                            <DownChevron />
                                        </span>
                                    </>
                                )}
                            </div>
                            {isSubscribedOffersListEmpty && (
                                <p
                                    className={mergeClasses([
                                        styles.m0,
                                        styles.textDarkgray,
                                    ])}
                                >
                                    Earn double or more points on what you buy
                                    most.
                                </p>
                            )}
                        </div>
                    )}

                    {isSubscribedOffersListEmpty && (
                        <Button
                            variant="outlined-rounded"
                            borderColor="borderPurple"
                            color="textPurple"
                            bgColor="bgWhite"
                            align="alignSelfStart"
                            onClick={() => {
                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'Add',
                                        element_location:
                                            'Your bonus points categories',
                                        event_rewards_number:
                                            userInfo?.rewardsNumber?.padStart(
                                                10,
                                                '0'
                                            ) || '',
                                    }
                                );
                                setIsFavoriteSelectionDrawerOpen(true);
                            }}
                        >
                            Make a selection
                        </Button>
                    )}

                    {!isSubscribedOffersListEmpty && expandCategory && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className={styles.cardRow}>
                                <SelectedOffers
                                    subscribedList={subscribedList}
                                    isSubscribedOffersListEmpty={
                                        isSubscribedOffersListEmpty
                                    }
                                    setInfoDrawerOpenOffer={
                                        setInfoDrawerOpenOffer
                                    }
                                    setIsFavoriteSelectionDrawerOpen={
                                        setIsFavoriteSelectionDrawerOpen
                                    }
                                />
                            </div>
                        </motion.div>
                    )}
                </div>
            </div>

            <SwipeableDrawer
                anchor="bottom"
                open={isFavoriteSelectionDrawerOpen}
                onClose={() => { setIsFavoriteSelectionDrawerOpen(false); openChooseCategory(); }}
                onOpen={() => setIsFavoriteSelectionDrawerOpen(true)}
            >
                <div
                    className={mergeClasses(
                        styles.p8,
                        styles.favoriteSelectionCarouselContainer
                    )}
                >
                    <FavoriteSelectionCarousel
                        variant="manage-favorites"
                        handleClose={() =>
                            setIsFavoriteSelectionDrawerOpen(false)
                        }
                    />
                </div>
            </SwipeableDrawer>
            {offerList && offerList.length > 0 && infoDrawerState.open && (
                <InfoDrawer
                    open={infoDrawerState.open}
                    setIsOpen={toggleInfoDrawerOpen}
                    offerList={offerList as FavoriteOffersList}
                    openOfferId={infoDrawerState.selectedOffer}
                />
            )}
        </>
    );
}

export default ManageFavorites;
