import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, CardContent, Typography } from '@material-ui/core';
import {
    eventMetricsMap,
    useEventsReporter,
    useGuestMode,
    useUserInfo,
} from 'src/hooks';
import { useQuery } from 'react-query';
import { Player } from '@lottiefiles/react-lottie-player';
import { getStaplesRewardsData } from 'src/lib/api-client/wallet-activities';
import ScrollableRow from '../../scrollable-row';
import useDragScroll from '../../../hooks/useDragScroll';
import ScrollableCard from '../scrollable-card';
import styles from './styles.module.scss';
import useRouter from '../../../hooks/useRouter';
import { Body, Heading } from '../../UI';
import ROUTES from '../../../lib/routes';
import { CACHE_ONLY } from '../../../lib/utils/react-query-utils';

interface QuickLinksProps {
    setQuickLinkUrl: (data: string) => void;
}

export function QuickLinksCard({ setQuickLinkUrl }: QuickLinksProps) {
    const scrollProps = useDragScroll();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo } = useUserInfo();
    const { isGuestModeOn } = useGuestMode();
    const { data: staplesRewardsDetail } = useQuery(
        ['details', userInfo?.rewardsNumber],
        () => getStaplesRewardsData(userInfo?.rewardsNumber),
        {
            ...CACHE_ONLY,
        }
    );

    const quickLinks = [
        // TODO remove this link after 29th december 2024
        {
            id: 'holidayCards',
            name: 'Holiday cards',
            type: 'external',
            serviceIcon: require('../../../assets/lotties/quick_links_holiday_cards.json'),
            serviceLink: `${process.env.REACT_APP_DOT_COM_URL}/services/printing/custom-cards/holiday-cards/?utm_source=staples_app&utm_medium=referral&utm_campaign=omni-homepage&utm_term=pto-cards&utm_content=quicklinks`,
        },
        {
            id: 'startAShipment',
            name: 'Start a shipment',
            serviceIcon: require('../../../assets/icons/icon_start_a_shipment.svg'),
            serviceLink: `${ROUTES.shippingServices}?backpath=${ROUTES.home}`,
        },
        {
            id: 'buyItAgain',
            name: 'Buy it again',
            type: 'external',
            serviceIcon: require('../../../assets/icons/icon_buy_again.svg'),
            serviceLink: `${process.env.REACT_APP_DOT_COM_URL}/ptd/easyreorder`,
        },
        {
            id: 'activateOffers',
            name: 'Activate offers',
            serviceIcon: require('../../../assets/icons/icon_easy_rewards.svg'),
            serviceLink: `${ROUTES.rewardsWallet}?tabIndex=1`,
        },
        {
            id: 'inStoreCoupons',
            name: 'In-store coupons',
            serviceIcon: require('../../../assets/icons/icon_coupons.svg'),
            serviceLink: `${ROUTES.rewardsWallet}?tabIndex=2`,
        },
        {
            id: 'weeklyAd',
            name: 'Weekly Ad',
            type: 'external',
            serviceIcon: require('../../../assets/icons/icon_weekly_ad.svg'),
            serviceLink: `${process.env.REACT_APP_DOT_COM_URL}/lp/weekly-ad?utm_source=staples_app&utm_medium=app&utm_campaign=omni-app-home-screen&utm_term=weekly-ad-link`,
        },
        {
            id: 'autoRestock',
            name: 'Auto Restock',
            type: 'external',
            serviceIcon: require('../../../assets/icons/icon_restock.svg'),
            serviceLink: `${process.env.REACT_APP_DOT_COM_URL}/ars/sparq/subscriptions`,
        },
        {
            id: 'onlineDeals',
            name: 'Online deals',
            type: 'external',
            serviceIcon: require('../../../assets/icons/icon_online_deals.svg'),
            serviceLink: `${process.env.REACT_APP_DOT_COM_URL}/deals/Staples-Daily-Deals/SEGDD0000001`,
        },
        {
            id: 'storeServices',
            name: 'Store Services',
            serviceIcon: require('../../../assets/icons/icon_in_store.svg'),
            serviceLink: ROUTES.stores,
        },
        {
            id: 'giftCardBalance',
            name: 'Gift Card balance',
            type: 'external',
            serviceIcon: require('../../../assets/icons/icon_card.svg'),
            serviceLink: `${process.env.REACT_APP_DOT_COM_URL}/cc/mmx/giftcard`,
        },
    ];
    const guestModeQuickLinks = [
        {
            id: 'weeklyAd',
            name: 'Weekly Ad',
            type: 'external',
            serviceIcon: require('../../../assets/icons/icon_weekly_ad.svg'),
            serviceLink: `${process.env.REACT_APP_DOT_COM_URL}/lp/weekly-ad?utm_source=staples_app&utm_medium=app&utm_campaign=omni-app-home-screen&utm_term=weekly-ad-link`,
        },
        {
            id: 'onlineDeals',
            name: 'Online deals',
            type: 'external',
            serviceIcon: require('../../../assets/icons/icon_online_deals.svg'),
            serviceLink: `${process.env.REACT_APP_DOT_COM_URL}/deals/Staples-Daily-Deals/SEGDD0000001`,
        },
        {
            id: 'storeServices',
            name: 'Store Services',
            serviceIcon: require('../../../assets/icons/icon_in_store.svg'),
            serviceLink: ROUTES.stores,
        },
        {
            id: 'giftCardBalance',
            name: 'Gift Card balance',
            type: 'external',
            serviceIcon: require('../../../assets/icons/icon_card.svg'),
            serviceLink: `${process.env.REACT_APP_DOT_COM_URL}/cc/mmx/giftcard`,
        },
    ];

    const handleOnClickNavigation = (quickLink: any) => {
        analyticsCustomEvent(eventMetricsMap.home, {
            click_text: quickLink.name?.toLowerCase(),
            element_location: 'quick links',
        });
        if (quickLink.type === 'external') {
            setQuickLinkUrl(quickLink.serviceLink);
        } else if (quickLink.serviceLink) {
            return history.push(quickLink.serviceLink);
        }
        return null;
    };

    const [quickLinkItems, setQuickLinkItems] = useState(
        isGuestModeOn ? guestModeQuickLinks : quickLinks
    );

    useEffect(() => {
        if (isGuestModeOn) {
            setQuickLinkItems(guestModeQuickLinks);
        } else {
            setQuickLinkItems(quickLinks);
        }
    }, [isGuestModeOn]);

    useEffect(() => {
        const storeCredit = staplesRewardsDetail?.data?.couponDataList?.filter(
            (data: any) => data?.couponInfo?.couponClass === 'StoreCredit'
        );
        if (storeCredit?.length) {
            const storeCreditQuickLink = {
                id: 'usePromoCard',
                name: 'Promo card',
                serviceIcon: require('../../../assets/lotties/icon_promo_card.json'),
                serviceLink: `${ROUTES.coupons}?couponNumber=${storeCredit[0].couponNumber}&deepLink=false`,
            };
            setQuickLinkItems([storeCreditQuickLink, ...quickLinks]);
        }
    }, [staplesRewardsDetail]);

    // TODO remove this useEffect after 29th december 2024
    useEffect(() => {
        const currentDate = new Date().getTime();
        const endDate = new Date('December 29, 2024 00:00:00').getTime();
        const timeElapsed = currentDate - endDate;
        if (timeElapsed >= 0 && !isGuestModeOn) {
            setQuickLinkItems((oldQuickLinks) =>
                oldQuickLinks.filter((link) => link.id !== 'holidayCards')
            );
        }
    }, [isGuestModeOn]);

    return (
        <motion.div initial={{ height: 'auto' }} exit={{ height: 0 }}>
            <Box className={styles.quickLinksContainer}>
                <Heading type="h2" size={16} weight="bold">
                    What can we help you with today ?
                </Heading>
                <ScrollableRow
                    id="quick-links-scrollable-row"
                    scrollProps={scrollProps}
                    showShadow={false}
                    className={styles.quickLinksScrollableAlign}
                >
                    {quickLinkItems.map((quickLink: any) => (
                        <ScrollableCard
                            noPadding
                            className={styles.quickLinkCard}
                            role="button"
                        >
                            <CardContent
                                className={styles.cardContainer}
                                onClick={() =>
                                    handleOnClickNavigation(quickLink)
                                }
                            >
                                {quickLink.id === 'usePromoCard' ||
                                    quickLink.id === 'holidayCards' ? (
                                    <Player
                                        autoplay
                                        loop
                                        src={quickLink.serviceIcon}
                                        style={{
                                            height: '36px',
                                            width: '36px',
                                        }}
                                    />
                                ) : (
                                    <img
                                        className={styles.serviceIcon}
                                        src={quickLink.serviceIcon}
                                        alt={quickLink.name}
                                        aria-hidden="true"
                                    />
                                )}

                                <Body
                                    className={styles.cardTitle}
                                    weight="light"
                                    size={12}
                                    lineHeight={16}
                                >
                                    {quickLink.name}
                                </Body>
                            </CardContent>
                        </ScrollableCard>
                    ))}
                </ScrollableRow>
            </Box>
        </motion.div>
    );
}

export default QuickLinksCard;
